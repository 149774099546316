<template>
  <div :class="['handsg-header', {'is-flag': $flag}]">
    <div class="web-header" :style="topBannerBg">
      <div v-if="!$flag" class="header">
        <div class="title">
          <span>欢迎来到汇图科技！</span>
          <span>客服：15826612127</span>
        </div>
      </div>
      <div class="menu">
        <a href="/" class="logo-alink">
          <img :style="oldLogo" class="logo-old" src="./assets/images/logo.png" @click="handleSkipToHome" />
          <img :style="newLogo" class="logo-new" src="./assets/images/n-logo.png" @click="handleSkipToHome" />
        </a>
        <div v-if="!$flag" class="menu-pannel">
          <div class="menu-li" v-for="(menu, index) in menuList" :key="index">
            <div class="li-name">
              <span class="name-text" :class="activeIndex === index ? 'active-name' : ''"
                @click="handleSkipToPage(menu, index)">{{ menu.name }}</span>
            </div>
            <div :style="activeLine" class="li-active" :class="activeIndex === index ? 'is-li-active' : ''"></div>
          </div>
        </div>
        <div v-else>
          <img :style="oldLogo" class="func-btn logo-old" src="./assets/images/func.png" @click="show = !show">
          <img :style="newLogo" class="func-btn logo-new" src="./assets/images/n-func.png" @click="show = !show">
        </div>
      </div>
    </div>

    <transition name="el-fade-in-linear">
      <el-card v-show="show" class="box-card flag-card">
        <div v-for="(menu, index) in menuList" :key="index" @click="handleSkipToPage(menu, index)">
          <span class="name-text tc">{{ menu.name }}</span>
        </div>
      </el-card>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'HeaderItem',
  data() {
    return {
      show: false,
      menuList: [
        {
          name: '网站首页',
          url: '/'
        },
        {
          name: '关于我们',
          url: '/AboutUs'
        },
        {
          name: '产品中心',
          url: '/ProductCenter'
        },
        {
          name: '联系我们',
          url: '/ContactUs'
        }
      ],
      activeIndex: 0,
      topBannerBg: {
        backgroundColor: '',
        cololr: '',
        boxShadow: ''
      },
      activeLine: {
        background: ''
      },
      newLogo: {},
      oldLogo: {}
    }
  },
	watch: {
		'$route.path': {
			handler(val) {
        this.activeIndex = this.menuList.findIndex((item) => {
          return item.url == val
        })
			},
      immediate: true
		}
	},
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // 监听页面滚动
  },
  // 滚动之前重置
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // 滚动页面时触发导航变色
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop) {
        this.topBannerBg.backgroundColor = '#ffffff';
        this.topBannerBg.boxShadow = '0px 3px 10px rgb(167, 167, 167)';
        this.topBannerBg.color = '#1C7BA7';
        this.newLogo.display = 'block';
        this.oldLogo.display = 'none';
        this.activeLine.background = '#0172BD';
      } else {
        this.topBannerBg.backgroundColor = 'rgba(3, 150, 255, 0.5)';
        this.topBannerBg.boxShadow = '';
        this.topBannerBg.color = '#fff';
        this.newLogo.display = 'none';
        this.oldLogo.display = 'block';
        this.activeLine.background = '#ffffff';
      }
    },
    // 导航栏跳转
    handleSkipToPage(menu, index) {
      this.activeIndex = index;
      this.$router.push({
        path: menu.url,
      });
      this.show=false
    },
    handleSkipToHome() {
      this.$router.push({ path: '/' });
    },
  }
};
</script>

<style lang="less">
@import './assets/css/index.less';
</style>
