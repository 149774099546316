<template>
	<div :class="['home', {'is-flag': $flag}]">
		<div :class="['banner-container', {'is-flag': $flag}]">
			<div class="banner-content">
				<div class="text">
					<div class="banner-title">致力于提高医疗<br />机构数字化</div>
					<div class="banner-desc">
						以客户需求为己任，不断创新，为不同客户提供更<br />加具有性价比的医疗产品服务
					</div>
				</div>
			</div>
		</div>

		<!-- 行业优势 -->
		<div class="industry">
			<div class="industry-header tc">
				<div class="title">行业优势</div>
				<div class="desc">INDUSTRY ADVANTAGES</div>
				<div class="line"></div>
			</div>
			<div class="industry-content">
				<div class="industry-item" v-for="(item, index) in industry" :key="index">
					<img :src="item.image" />
					<div class="text tc">{{ item.txt }}</div>
				</div>
			</div>
		</div>

		<!-- 核心产品 -->
		<div class="core">
			<div class="core-header tc">
				<div class="title">核心产品</div>
				<div class="desc">INDUSTRY ADVANTAGES</div>
				<div class="line"></div>
			</div>
			<div :class="['core-content', {'isFlag': $flag}] ">
				<img v-if="core[activeName] && $flag" :src="core[activeName].image">
				<el-collapse  v-model="activeName" accordion @change="handleChange">
					<el-collapse-item v-for="(item, index) in core" :key="index" :name="index">
						<template slot="title">
							<span :class="['circle', {'isCircle' : activeName == index }]"></span>{{ item.txt }}
						</template>
						<div class="desc" v-html="item.desc"></div>
						<span class="more tc">MORE>></span>
					</el-collapse-item>
				</el-collapse>
				<img v-if="core[activeName]  && !$flag" :src="core[activeName].image">
			</div>
		</div>

		<!-- 关于我们 -->
		<div class="about-us">
			<div class="about-us-header tc">
				<div class="title">关于我们</div>
				<div class="desc">ABOUT US</div>
				<div class="line"></div>
			</div>
			<div class="about-us-content">
				<div class="about-us-item" v-for="(item, index) in about" :key="index">
					<img :src="item.image" />
					<div class="text tc">{{ item.txt }}</div>
				</div>
			</div>
			<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;汇图科技（浙江）有限公司于2023年05月成立，是一家以医学影像云存储、管理为基础，提供了医学影像共享、远程会诊、双向转诊、移动阅片、远程影像诊断等为核心服务的智慧医疗服务型公司。</p>
			<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们致力于提高医疗机构信息化、移动化水平及跨院业务协作，为患者、医生、医疗机构提供专业医学数字影像服务。科学的将云计算、大数据、人工智能等技术与医学影像创新融合，打造“专业影像云+人工智能应用平台”，并以平台为基，服务医疗全场景、全流程，构建数字医学影像应用生态。公司秉持以市场需求为向导，以客户需求为己任，通过不断创新，为不同客户提供更加具有性价比的医疗产品服务。</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'IndexHome',
	data() {
		return {
			industry: [
				{
					image: require('../assets/images/industry-1.png'),
					txt: '服务企业'
				},
				{
					image: require('../assets/images/industry-2.png'),
					txt: '精尖人才'
				},
				{
					image: require('../assets/images/industry-3.png'),
					txt: '服务终端'
				}
			],
			core: [
				{
					image: require('../assets/images/core-1.png'),
					txt: '云影像后台',
					desc: '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;医学云影像服务作为云端系统，主要用于医学机构、学术组织及医院间的存储、共享以及医学图像的处理，它可以让用户获取医学影像数据、加强医疗机构间的协作、共享患者的医学影像检查结果。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;多维度影像处理工具(窗宽窗位、测量、标注等)、MPR、三维建模支持虚拟切割，实现精准辅助诊断。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;自定义新增编辑诊断模版，一键填充报告模板，通过云影像平台传输给患者。</p>'
				},
				{
					image: require('../assets/images/core-2.png'),
					txt: '云影像小程序',
					desc: '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;患者在做X线检查、CT、磁共振等影像检查后，可通过二维码扫描在手机、iPad、电脑等终端，随时随地查看、下载或分享影像数据。医生能够快速在终端上，随时随地查看患者的的影像、书写报告、审核报告、速调阅患者的历史检查。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;云影像获取方式支持短信、微信公众号、小程序、APP、报告嵌入二维码多种方式，根据医院需要自定义影像调阅配置。</p>'
				},
				{
					image: require('../assets/images/core-3.png'),
					txt: 'SAAS运营后台',
					desc: '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可视化影像上传数据显示，直观掌握院内影像统计信息，清晰可追溯的检查任务列表，集成患者影像数据，基于不同场景自主对患者影像、报告进行补录、新增操作。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过实时监测，智能预警报警，灵活出警调度，事件溯源管理等方式，全面提升项目运维能力，形成“日常监管—事前预警—事中处置—事后评估”的闭环管理模式。</p>'
				}
			],
			about: [
				{
					image: require('../assets/images/about-1.png'),
					txt: '医学影像共享'
				},
				{
					image: require('../assets/images/about-2.png'),
					txt: '远程会诊'
				},
				{
					image: require('../assets/images/about-3.png'),
					txt: '双向转诊'
				},
				{
					image: require('../assets/images/about-4.png'),
					txt: '移动阅片'
				}
			],
			activeName: 0
		};
	},
    methods: {
      handleChange(val) {
        console.log(this.$flag);
		if (!val) {
			this.activeName = 0;
		}
      }
    }
};
</script>

<style lang="less" scoped>
@import '../assets/css/home.less';
</style>
