<template>
  <div class="website-container">
    <Header-list ref="header"/>
    <div>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </div>
    <Footer-list ref="footer"/>
  </div>
</template>

<script>
import HeaderList from './components/header/index.vue'
import FooterList from './components/footer/index.vue'
export default {
  name: 'LayOut',
  components: {
    FooterList,
    HeaderList
  }
}
</script>

<style lang="less">
html,
body {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

.website-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
</style>
