import Vue from 'vue';
import Router from 'vue-router';
import routerPC from './pc-router';

Vue.use(Router);

// 创建路由实例
const router = routerPC;

export default router;
