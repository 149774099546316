<template>
  <div :class="$flag ? 'anProduct' : 'pcProduct'">
    <div class="top-img">
      <img :src="bannerUrl" alt="" />
    </div>
    <div class="product-main">
      <div class="type-box">
        <div
          :class="num == item.id ? 'active list-item' : 'list-item'"
          v-for="item in typeList"
          :key="item.id"
          @click="changeOne(item.id)"
        >
          <img :src="item.url" alt="" />
          <div>{{ item.title }}</div>
        </div>
      </div>
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="云影后台系统" name="first" v-if="num == 1">
            <div class="item-box">
              <div class="carousel-container">
                <img
                  class="left-jt jt"
                  src="../assets/imgs/left.png"
                  alt=""
                  @click="prevSlide"
                />
                <el-carousel
                  :interval="4000"
                  arrow="never"
                  indicator-position="none"
                  ref="carousel"
                >
                  <el-carousel-item v-for="item in imgurl" :key="item.id">
                    <img class="banner-img" :src="item.url" alt="" />
                  </el-carousel-item>
                </el-carousel>
                <img
                  class="right-jt jt"
                  src="../assets/imgs/right.png"
                  @click="nextSlide"
                  alt=""
                />
              </div>
              <div class="item-content">
                <div class="title1">云影像应用平台</div>
                <div class="title2">
                  ——Cloud Shadow Image Application Platform
                </div>
                <div class="title3">
                  医学云影像服务作为云端系统，主要用于医学机构、学术组织及医院间的存储、共享以及医学图像的处理，它可以让用户获取医学影像数据、加强医疗机构间的协作、共享患者的医学影像检查结果。
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="云影小程序" name="second" v-if="num == 1">
            <div class="item-box">
              <img class="phone-style" src="../assets/imgs/phone.png" alt="" />
              <div class="item-content">
                <div class="title1">云影像小程序</div>
                <div class="title2">——Cloud Shadow Image Applet</div>
                <div class="title3">
                  患者在做X线检查、CT、磁共振等影像检查后，可通
                  过二维码扫描在手机、iPad、电脑等终端，随时随地查
                  看、下载或分享影像数据。医生能够快速在终端上，随时
                  随地查看患者的的影像、书写报告、审核报告、速调阅患
                  者的历史检查。
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="SAAS运营后台" name="first" v-if="num == 2">
            <div class="item-box">
              <div class="carousel-container">
                <img
                  class="left-jt jt"
                  src="../assets/imgs/left.png"
                  alt=""
                  @click="prevSlide"
                />
                <el-carousel
                  :interval="4000"
                  arrow="never"
                  indicator-position="none"
                  ref="carousel"
                >
                  <el-carousel-item v-for="item in imgurl1" :key="item.id">
                    <img class="banner-img" :src="item.url" alt="" />
                  </el-carousel-item>
                </el-carousel>
                <img
                  class="right-jt jt"
                  src="../assets/imgs/right.png"
                  @click="nextSlide"
                  alt=""
                />
              </div>
              <div class="item-content">
                <div class="title1">SAAS运营后台</div>
                <div class="title2">——SAAS operation backend</div>
                <div class="title3">
                  主要面向公司和租户公司内部员工使用。Saas产品
                  进入进攻期后，客户量增多，项目中心转移为客户成功，
                  需要有一个运营管理后台提高运营人员的工作效率，保
                  证客户得到的服务质量。
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="智能AI+大数据智能诊断"
            name="first"
            v-if="num == 3"
          >
            <div class="item-box">
              <img class="phone-style" src="../assets/imgs/pic6.png" alt="" />
              <div class="item-content">
                <div class="title1">大数据智能诊断平台</div>
                <div class="title2">
                  ——Big Data Intelligent Diagnosis Platform
                </div>
                <div class="tips-box">

                  <el-popover
                    v-for="item in tips"
                    :key="item.id"
                    placement="top-start"
                    width="220"
                    trigger="hover"
                  >
                    <div :class="$flag?'anPopContent':'pcPopContent'">{{item.content}}</div>
                    <el-button slot="reference">{{ item.title }}</el-button>
                  </el-popover>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCenter',
  data() {
    return {
      activeName: 'first',
      bannerUrl: this.$flag
        ? require('@/assets/imgs/banner2.png')
        : require('@/assets/imgs/product.png'),
      num: 1,
      imgurl: [
        { id: 1, url: require('@/assets/imgs/pic5.png') },
        { id: 2, url: require('@/assets/imgs/pic1.png') },
      ],
      imgurl1: [
        { id: 1, url: require('@/assets/imgs/pic2.png') },
        { id: 2, url: require('@/assets/imgs/pic3.png') },
      ],
      typeList: [
        {
          id: 1,
          title: '医疗影像系统',
          url: require('@/assets/imgs/icon-pro1.png'),
        },
        {
          id: 2,
          title: '企业定制服务',
          url: require('@/assets/imgs/icon-pro3.png'),
        },
        {
          id: 3,
          title: '大数据人工智能',
          url: require('@/assets/imgs/icon-pro2.png'),
        },
      ],
      tips: [
        {
          id: 1,
          title: '体质辨识',
          content: '辨识出患者的体质情况并给出合理的调理建议',
        },
        { id: 2, title: '电子病历', content: '保存病人的望闻问切信息' },
        { id: 3, title: '后台管理', content: '后台数据的维护管理' },
        { id: 4, title: '辩证论治', content: '选择疾病和病症，系统推荐处方' },
        { id: 5, title: '知识库', content: '医生学习园地' },
        { id: 6, title: '直接选方', content: '通过处方名开方' },
        { id: 7, title: '历史病例', content: '可查看历史就诊病例' },
        { id: 8, title: '协定方', content: '医生通过设置协定方开方' },
        { id: 9, title: '手动开方', content: '医生手动进行开方' },
        {
          id: 10,
          title: '专业化培训',
          content: '行业专家为团队设计培训流程及内容',
        },
        {
          id: 11,
          title: '定制化培训',
          content:
            '根据培训学院、合作对象、培训要求等定制不同专业课程及培训流程',
        },
        {
          id: 12,
          title: '系统化培训',
          content:
            '全科医生成长各阶段课程及师资培训等课程呈现各学科系列化、阶段化特点',
        },
        {
          id: 13,
          title: '混合式培训',
          content: '线上培训、线下面授和线上线下相结合多种方式',
        },
        {
          id: 14,
          title: 'pc+移动端结合',
          content: '线上课程学习多端口进入，例如pc、app、微信公众号等',
        },
      ],
    }
  },
  methods: {
    changeOne(n) {
      this.num = n
      this.activeName = 'first'
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    prevSlide() {
      this.$refs.carousel.prev()
    },
    nextSlide() {
      this.$refs.carousel.next()
    },
  },
}
</script>
<style lang="less">
/* PC端样式 */
.pcProduct {
  .top-img {
    > img {
      width: 100%;
      height: 37.5rem;
    }
  }
  .product-main {
    max-width: 1200px;
    margin: 0 auto;
    .type-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 40px 0;
    }
    .list-item {
      width: 360px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 4px rgba(212, 228, 243, 0.64);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 0;
      img {
        width: 100px;
        height: 78px;
        margin-bottom: 30px;
      }
      > div {
        font-size: 28px;
        color: #8b99a0;
      }
    }
    .active {
      border: 2px solid #0172bd;
      > div {
        color: #0172bd;
      }
    }
  }
  .item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 0;
    flex-wrap: wrap;
  }
  .item-content {
    width: 45%;
    .title1 {
      font-size: 30px;
      font-weight: 400;
      color: #0172bd;
    }
    .title2 {
      font-size: 20px;
      color: #b1d2e8;
      margin-top: 10px;
    }
    .title3 {
      font-size: 22px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
      text-indent: 2em;
      margin-top: 20px;
    }
  }
  .banner-img {
    width: 100%;
    height: 100%;
  }
  .carousel-container {
    width: 45%;
    padding: 0 30px;
    position: relative;
  }
  .jt {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 52px;
    cursor: pointer;
  }
  .left-jt {
    left: 0px;
  }
  .right-jt {
    right: 0px;
  }
  .phone-style {
    width: 50%;
  }
  .el-tabs__item {
    height: 50px;
    font-size: 30px;
    color: #8b99a0;
  }
  .el-tabs__active-bar {
    height: 7px;
  }
  .el-tabs__item.is-active {
    font-size: 40px;
  }
  .tips-box {
    margin-top: 20px;
    .el-button {
      margin: 0 15px 15px 0;
      border: 1px solid transparent;
      background: #e9eff2;
      font-size: 22px;
      padding: 10px 12px;
    }
    .el-button:hover {
      background: #daf4ff;
      border: 1px solid #2d83fe;
    }
   
  }
 
}

/* 移动端样式 */
.anProduct {
  .top-img {
    > img {
      width: 100%;
      height: 37.5rem;
    }
  }
  .product-main {
    padding: 70px;
    .type-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
    }
    .list-item {
      width: 30%;
      background: #ffffff;
      box-shadow: 0px 0px 20px 4px rgba(212, 228, 243, 0.64);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 35px 0;
      img {
        width: 101px;
        height: 78px;
        margin-bottom: 24px;
      }
      > div {
        font-size: 30px;
        color: #8b99a0;
      }
    }
    .active {
      border: 2px solid #0172bd;
      > div {
        color: #0172bd;
      }
    }
  }
  .item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
    flex-wrap: wrap;
  }
  .item-content {
    width: 100%;
    .title1 {
      font-size: 40px;
      font-weight: 400;
      color: #0172bd;
      padding-top: 60px;
    }
    .title2 {
      font-size: 30px;
      color: #b1d2e8;
      margin-top: 20px;
    }
    .title3 {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-indent: 2em;
      margin-top: 20px;
    }
  }
  .banner-img {
    width: 100%;
    height: 446px;
  }
  .carousel-container {
    width: 100%;
    padding: 0 30px;
    position: relative;
  }
  .jt {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 52px;
    cursor: pointer;
  }
  .left-jt {
    left: 10px;
  }
  .right-jt {
    right: 10px;
  }
  .phone-style {
    width: 100%;
  }
  .el-carousel__container {
    height: 440px;
    width: 86%;
    margin: 0 auto;
  }
  .el-tabs__item {
    height: 50px;
    font-size: 30px;
    color: #8b99a0;
  }
  .el-tabs__active-bar {
    height: 7px;
  }
  .el-tabs__item.is-active {
    font-size: 40px;
  }
  .tips-box {
    margin-top: 30px;
    .el-button {
      margin: 0 20px 20px 0;
      border: 1px solid transparent;
      background: #e9eff2;
      font-size: 36px;
    }
    .el-button:hover {
      background: #daf4ff;
      border: 1px solid #2d83fe;
    }
  }
}
// pc端
.pcPopContent{
      font-size: 24px ;
      color: #999999 ;
}
// 移动
.anPopContent{
  font-size: 30px ;
  color: #999999 ;
}
</style>
