// pc端路由
import PcLayout from '@/page/layout.vue'
import PcHome from '@/page/index.vue'
import AboutUs from '@/page/aboutUs.vue'
import ProductCenter from '@/page/productCenter.vue'
import ContactUs from '@/page/contactUs.vue'

let routes = [
  { path: '/',
    component: PcLayout,
    name: 'Hand Global',
    redirect: '/',
    children: [
      { path: '/', component: PcHome, name: '', meta: { keepAlive: true}},
      { path: '/AboutUs', component: AboutUs, name: '', meta: { keepAlive: true}},
      { path: '/ProductCenter', component: ProductCenter, name: '', meta: { keepAlive: true}},
      { path: '/ContactUs', component: ContactUs, name: '', meta: { keepAlive: true}}
    ]
  },
  {
    path: '*',
    hidden: true,
    redirect: { path: '/' }
  },
];
export default routes;
