<template>
  <div :class="['footer', {'is-flag': $flag}]">
    <div class="footer-top">
      <div class="contact-us tc">
        联系我们
        <div class="en">CONTACT US</div>
        <div class="line"></div>
      </div>
      <div class="desc tc">
        <span>开放</span>
        <span>包容</span>
        <span>专业</span>
        <span>创新</span>
      </div>
      <div class="info">
        <ul class="info-left">
          <li v-for="(item, index) in info" :key="index">
            <img :src="item.url">
            <div class="txt">{{ item.text }}</div>
          </li>
        </ul>
        <div class="info-right">
          <img src="./assets/images/htkj.png">
          <img src="./assets/images/qrcode_for_gh_e33ab65b03e5_344.jpg">
        </div>
      </div>
    </div>
    <div class="footer-bottom tc">
      <div class="footer-info">
        Copyright © 2023-2025 浙ICP备2023019936号-1
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterOut',
  data() {
    return {
      info: [
        {
          url: require('./assets/images/Phone.png'),
          text: '15826612127'
        },
        {
          url: require('./assets/images/Email.png'),
          text: 'liuzhitao@huitutech.com'
        },
        {
          url: require('./assets/images/Web.png'),
          text: 'www.huitutech.com'
        },
        {
          url: require('./assets/images/Location.png'),
          text: '地址：浙江省丽水市龙泉市龙渊街道滨江花园德馨居3幢公寓306室'
        },
      ]
    }
  }
};
</script>

<style lang="less" scoped>
@import './assets/css/index.less';
</style>
