<template>
  <div :class="$flag ? 'anAbout' : 'pcAbout'">
    <div class="top-img">
      <img :src="imgUrl" alt="" />
    </div>
    <div class="companyProfile">
      <img
        v-if="!$flag"
        class="company-pic"
        src="../assets/imgs/pic4.png"
        alt=""
      />
      <div>
        <div class="profile">
          <div class="profile-tip">公司简介</div>
          <span class="profile-tip line"> /</span>
          <span class="english">Company Profile</span>
        </div>
        <div class="profile-content margin25">
          汇图科技（浙江）有限公司于2023年05月成立，是一家以医学影像云存储、管理为基础，提供了医学影像共享、远程会诊、双向转诊、移动阅片、远程影像诊断等为核心服务的智慧医疗服务型公司。
        </div>
        <div class="profile-content">
          我们致力于提高医疗机构信息化、移动化水平及跨院业务协作，为患者、医生、医疗机构提供专业医学数字影像服务。科学的将云计算、大数据、人工智能等技术与医学影像创新融合，打造“专业影像云+人工智能应用平台”，并以平台为基，服务医疗全场景、全流程，构建数字医学影像应用生态。公司秉持以市场需求为向导，以客户需求为己任，通过不断创新，为不同客户提供更加具有性价比的医疗产品服务。
        </div>
      </div>
    </div>
    <div>
      <img class="bigImage" :src="yearUrl" alt="" />
    </div>
    <div class="about-type">
      <div class="typetitle">服务类型</div>
      <div class="Service">Service Type</div>
      <div class="Service-line"></div>
      <div class="type-box">
        <div class="list-item" v-for="item in typeList" :key="item.id">
          <img :src="item.url" alt="" />
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      imgUrl: this.$flag
        ? require('@/assets/imgs/banner.png')
        : require('@/assets/imgs/aboutus.png'),
      yearUrl: this.$flag
        ? require('@/assets/imgs/year.png')
        : require('@/assets/imgs/background1.png'),
      typeList: [
        {
          id: 1,
          title: '医疗影像系统',
          url: require('@/assets/imgs/icon-pro1.png'),
        },
        {
          id: 2,
          title: '企业定制服务',
          url: require('@/assets/imgs/icon-pro3.png'),
        },
        {
          id: 3,
          title: '大数据人工智能',
          url: require('@/assets/imgs/icon-pro2.png'),
        },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less">
/* PC端样式 */
.pcAbout {
  .top-img {
    > img {
      width: 100%;
      height: 37.5rem;
    }
  }
  .companyProfile {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 80px 0;
    .company-pic {
      width: 500px;
      height: 450px;
      margin-right: 30px;
    }
    .profile {
      display: flex;
      align-items: baseline;
      .profile-tip {
        font-size: 40px;
        color: #276a93;
        line-height: 21px;
        border-bottom: 10px solid #bbe4ff;
      }
      .line {
        border-bottom: none;
        padding-left: 8px;
      }
      .english {
        font-size: 20px;
        color: #acc5d5;
        line-height: 21px;
      }
    }
    .profile-content {
      font-size: 20px;
      color: #595a5a;
      text-indent: 2em;
      line-height: 32px;
    }
    .margin25 {
      margin: 30px 0;
    }
  }
  .bigImage {
    width: 100%;
    height: 250px;
  }
  .about-type {
    max-width: 1200px;
    margin: 60px auto;
    .typetitle {
      text-align: center;
      font-size: 34px;
      font-weight: 500;
      color: #555555;
    }
    .Service {
      text-align: center;
      font-size: 18px;
      color: #b1b1b1;
      padding: 14px 0;
    }
    .Service-line {
      width: 65px;
      height: 3px;
      background: #177be4;
      margin: 0 auto;
    }
  }
  .type-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;
  }
  .list-item {
    width: 360px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 4px rgba(212, 228, 243, 0.64);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    img {
      width: 100px;
      height: 78px;
      margin-bottom: 30px;
    }
    > div {
      font-size: 28px;
      color: #8b99a0;
    }
  }
}
/* 移动端样式 */
.anAbout {
  .top-img {
    > img {
      width: 100%;
      height: 37.5rem;
    }
  }
  .companyProfile {
    padding: 50px;
    .company-pic {
      width: 100%;
    }
    .profile {
      display: flex;
      align-items: baseline;
      margin-top: 20px;
      .profile-tip {
        font-size: 50px;
        color: #276a93;
        line-height: 21px;
        border-bottom: 20px solid #bbe4ff;
      }
      .line {
        border-bottom: none;
        padding-left: 8px;
      }
      .english {
        font-size: 24px;
        color: #acc5d5;
        line-height: 21px;
      }
    }
    .profile-content {
      font-size: 36px;
      color: #595a5a;
      line-height: 50px;
      text-indent: 2em;
    }
    .margin25 {
      margin: 40px 0;
    }
  }
  .bigImage {
    width: 100%;
    height: 290px;
  }
  .about-type {
    padding: 20px 30px;
    .typetitle {
      text-align: center;
      font-size: 50px;
      font-weight: 500;
      color: #555555;
    }
    .Service {
      text-align: center;
      font-size: 24px;
      color: #b1b1b1;
      padding: 6px 0;
    }
    .Service-line {
      width: 80px;
      height: 5px;
      background: #177be4;
      margin: 0 auto;
      margin-top: 6px;
    }
  }
  .type-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 30px;
  }
  .list-item {
    width: 30%;
    background: #ffffff;
    box-shadow: 0px 0px 20px 4px rgba(212, 228, 243, 0.64);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
    img {
      width: 101x;
      height: 78px;
      margin-bottom: 10px;
    }
    > div {
      font-size: 30px;
      color: #8b99a0;
    }
  }
}
</style>
