import Vue from 'vue'
import App from './App'
import routes from './router'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' // pc端
import './assets/css/index.css'
import $ from 'jquery';

// 是否为手机端
const flag = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
if (flag) {
  // 移动端适配
  let ZoomNum = ((window.screen.availWidth * window.devicePixelRatio) /1440).toFixed(2);
  $('html').css('font-size', 17 * ZoomNum);
  $('#app').css('cssText', 'height:auto!important')
  $('#app').css({
    width: window.screen.availWidth,
  })
  var metaMobie = document.querySelector('meta[name=viewport]')
  metaMobie.content += `, initial-scale=${1 / window.devicePixelRatio},maximum-scale=2.0,minimum-scale=0,user-scalable=yes`;

  $('#app').css({
    width: window.screen.availWidth * window.devicePixelRatio,
  });
}

Vue.prototype.$flag = flag

Vue.use(ElementUI);

Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes
});

window.vm = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
