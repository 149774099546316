<template>
  <div :class="$flag ? 'anContact' : 'pcContact'">
    <div class="top-img">
      <img :src="imgUrl" alt="" />
    </div>
    <div class="map-box">
      <div id="mapContainer"></div>
      <div class="map-address">
        地址：浙江省丽水市龙泉市龙渊街道滨江花园德馨居3幢公寓306室
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data() {
    return {
      imgUrl: this.$flag
        ? require('@/assets/imgs/banner1.png')
        : require('@/assets/imgs/concent.png'),
    }
  },
  mounted() {
    const map = new window.BMapGL.Map('mapContainer') // "mapContainer" 是一个用来显示地图的容器元素的 ID，可以自行定义
    // 设置地图中心点和缩放级别
    const point = new window.BMapGL.Point(119.144997, 28.074208) // 设置地图中心点位置
    map.centerAndZoom(point, 16) // 设置缩放级别，参数为中心点和缩放级别
    // 创建标注点
    const marker = new window.BMapGL.Marker(point)

    // 将标注点添加到地图上
    map.addOverlay(marker)
    var infoWindow = new window.BMapGL.InfoWindow(
      '地址:浙江省丽水市龙泉市龙渊街道滨江花园德馨居3幢公寓306室'
    ) // 创建信息窗口并设置内容
    marker.addEventListener('click', function () {
      this.openInfoWindow(infoWindow) // 点击标注时打开信息窗口
    })
    map.enableScrollWheelZoom(true) // 启用鼠标滚轮缩放功能
  },
}
</script>
<style lang="less" scoped>
/* PC端样式 */
.pcContact {
  .top-img {
    > img {
      width: 100%;
      height: 37.5rem;
    }
  }
  .map-box {
    width: 62%;
    max-width: 960px;
    margin: 50px auto;
    position: relative;
  }

  #mapContainer {
    height: 600px; /* 设置合适的高度 */
    width: 100%; /* 设置合适的宽度 */
  }

  .map-address {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 432px;
    background: #555555;
    opacity: 0.72;
    font-size: 24px;
    color: #ffffff;
    padding: 16px;
  }
}
/* 移动端样式 */
.anContact {
  .top-img {
    > img {
      width: 100%;
      height: 37.5rem;
    }
  }
  .map-box {
    width: 90%;
    margin: 40px auto;
    position: relative;
  }

  #mapContainer {
    height: 900px; /* 设置合适的高度 */
    width: 100%; /* 设置合适的宽度 */
  }

  .map-address {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px;
    background: #555555;
    opacity: 0.72;
    font-size: 30px;
    color: #ffffff;
    padding: 10px;
    line-height: 36px;
  }
}
</style>
